import { motion, useInView } from "framer-motion";
import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";

const PartnerListing = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const aniItem = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const pageSize = module?.pageSize || 9;
  const [itemsToDisplay, setItemsToDisplay] = useState(pageSize);

  const onLoadMore = () => {
    setItemsToDisplay(itemsToDisplay + pageSize);
  };

  return (
    <section
      className={`logo-with-box bg-pattern3 lg:pb-100 pb-50${
        module?.extraClass ? " " + module.extraClass : ""
      }`}
    >
      <div className="container-fluid">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? "show" : "hidden"}
          variants={container}
          className="flex flex-wrap justify-start lg:mx-minus-15 mx-0 gap-y-10"
        >
          {module?.partner?.length > 0 &&
            module.partner.slice(0, itemsToDisplay).map((item, index) => (
              <motion.div
                variants={aniItem}
                className="md:w-4/12 lg:w-3/12 w-full md:px-15 px-0"
                key={index.toString()}
              >
                <div className="logo bg-white fade-ani">
                  <img
                    src={item?.image?.mediaItemUrl}
                    loading="lazy"
                    alt={item?.image?.altText}
                  />
                </div>
              </motion.div>
            ))}
        </motion.div>
        {module?.partner?.length > 0 &&
          module?.partner?.length > itemsToDisplay && (
            <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 lg:mt-100 mt-30 fade-ani">
              <button onClick={onLoadMore} className="button btn-border">
                <span>Load More Partners</span>
                <img
                  src="/images/new-header-btn.png"
                  loading="lazy"
                  alt="Timeless Africa Safaris image"
                />
              </button>
            </div>
          )}
      </div>
    </section>
  );
};

export default PartnerListing;
export const PartnerListingFragment = graphql`
  fragment PartnerListingFragment on WpPage_Pagecontent_PageContent_PartnerListing {
    id
    extraClass
    hideSection
    partner {
      image {
        altText
        mediaItemUrl
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 400)
      }
    }
    pageSize
  }
`;
