import { motion, useInView } from "framer-motion";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import { Autoplay, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const RelatedTravelStyle = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.8,
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  const handleNextClickStay = () => {
    const swiper = document.querySelector(".related-slider-row .swiper").swiper;
    swiper.slideNext();
  };

  return (
    <section
      className={`related-slider-row discover-ex-slider with-pattern lg:py-120 py-60${
        module?.extraClass ? " " + module.extraClass : ""
      }`}
    >
      <div className="container-fluid pr-0">
        <div className="flex flex-wrap justify-between pr-70">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={isInView || hasPlayed ? "show" : "hidden"}
            variants={container}
            className="section-title fade-ani"
          >
            {module?.heading && (
              <motion.div variants={item} className="title-black">
                <h3>{module.heading}</h3>
              </motion.div>
            )}
            {module?.description && (
              <motion.div variants={item} className="content ">
                {parse(module.description)}
              </motion.div>
            )}
          </motion.div>
          {module?.button?.title && (
            <motion.div
              variants={item}
              className="btn-custom flex flex-wrap items-center justify-center space-x-8 lg:mt-0 mt-15 fade-ani"
            >
              <Link to={module.button.url || "#"} className="button btn-border">
                <span>{module.button.title}</span>
                <img
                  src="/images/new-header-btn.png"
                  loading="lazy"
                  alt="Timeless Africa Safaris image"
                />
              </Link>
            </motion.div>
          )}
        </div>
        <motion.div
          initial="hidden"
          animate={isInView || hasPlayed ? "show" : "hidden"}
          variants={container2}
          id="related-slider-row"
          className="related-slider-row related-drag-section relative overflow-hidden discover-ex-slider-main lg:mt-50 mt-25 fade-ani"
        >
          <Swiper
            spaceBetween={20}
            loop={true}
            slidesPerView={3.5}
            centeredSlides={false}
            allowTouchMove={true}
            speed={1000}
            navigation={false}
            modules={[Autoplay, Pagination, Scrollbar]}
            className="related-slider"
            breakpoints={{
              100: {
                slidesPerView: 1.3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3.6,
                spaceBetween: 20,
              },
            }}
          >
            {module?.travelStyle?.length > 0 &&
              module.travelStyle.map((travelStyleContent, index) => (
                <SwiperSlide>
                  <Link
                    to={travelStyleContent.uri}
                    className="hover:cursor-grab"
                  >
                    <GatsbyImage
                      image={getImage(travelStyleContent.featuredImage.node)}
                      alt={travelStyleContent?.featuredImage?.node.altText}
                    />
                  </Link>
                  <Link to={travelStyleContent.uri} className="">
                    <div className="discover-ex-slider-content p-30 hover:cursor-pointer">
                      {travelStyleContent?.title && (
                        <div className="title-black">
                          <h6>{travelStyleContent.title}</h6>
                        </div>
                      )}
                      {travelStyleContent?.excerpt && (
                        <div className="content ">
                          {parse(travelStyleContent.excerpt)}
                        </div>
                      )}
                      {travelStyleContent?.uri && (
                        <div className="btn-custom related-btn-ignore flex flex-wrap items-center space-x-8 mt-20">
                          <div className="ignore-btn">
                            <span className="button btn-red-border">
                              Explore Travel Style
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="swiper-arrow swiper-arrow-red">
            <a
              className="button-next cursor-pointer absolute top-[38%] right-[17.5vw] lgscreen:right-[6vw]  mdscreen:right-[75px] translate-y-minus_50 z-9"
              onClick={handleNextClickStay}
            >
              <img
                src="/images/white-arrow.svg"
                loading="lazy"
                alt="Timeless Africa Safaris image"
              />
            </a>
          </div>
          {/* <div className="related-button-drag" id="related-button-drag" > <span className="button-text">Drag</span></div> */}
        </motion.div>
      </div>
    </section>
  );
};

export default RelatedTravelStyle;
export const RelatedTravelStyleFragment = graphql`
  fragment RelatedTravelStyleFragment on WpPage_Pagecontent_PageContent_RelatedTravelStyle {
    id
    hideSection
    extraClass
    heading
    description
    button {
      target
      title
      url
    }
    travelStyle {
      ... on WpTravel {
        id
        link
        excerpt
        title
        featuredImage {
          node {
            altText
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
          }
        }
        uri
      }
    }
  }
`;
export const SubExperienceRelatedTravelStyleFragment = graphql`
  fragment SubExperienceRelatedTravelStyleFragment on WpExperience_Experiencecontent_Experience_RelatedTravelStyle {
    id
    hideSection
    extraClass
    heading
    description
    button {
      target
      title
      url
    }
    travelStyle {
      ... on WpTravel {
        id
        link
        excerpt
        title
        featuredImage {
          node {
            altText
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1000)
          }
        }
        uri
      }
    }
  }
`;
