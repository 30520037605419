import ImageOpt from "./ImageOpt";
import { motion, useInView } from "framer-motion";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";

const ALL_DESTINATIONS = "all-destinations";

const tabsMeta = {
  "central-africa": "Central Africa",
  "east-africa": "East Africa",
  "indian-ocean-islands": "Indian Ocean Islands",
  "southern-africa": "Southern Africa",
};

const DestinationsListing = ({ module, location }) => {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const isInView = useInView(ref2);
  const isInView2 = useInView(ref3);

  const [hasPlayed, setHasPlayed] = useState(false);
  const [hasPlayed2, setHasPlayed2] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.9,
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  useEffect(() => {
    if (isInView2 && !hasPlayed2) {
      setHasPlayed2(true);
    }
  }, [isInView2, hasPlayed2, setHasPlayed2]);

  const params = new URLSearchParams(location.search);
  const tabKey = params.get("tab") || "";

  const pageSize = 15;

  const [tabOption, setTabOption] = useState(tabKey || ALL_DESTINATIONS);

  useEffect(() => {
    if (tabKey !== null && tabKey !== "all-destinations") {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (tabKey !== "" && tabOption !== tabKey) {
      setTabOption(tabKey);
    }
  }, []);

  const onTabChange = (tabHeading) => {
    params.set("tab", tabHeading);
    setTabOption(tabHeading);
  };

  const [numOfItemsToDisplay, setNumOfItemsToDisplay] = useState(pageSize);

  const onLoadMore = () => {
    setNumOfItemsToDisplay(numOfItemsToDisplay + pageSize);
  };

  const query = useStaticQuery(graphql`
    query {
      tabs: allWpDestination(
        sort: { order: ASC, fields: id }
        filter: { parentId: { eq: null } }
      ) {
        nodes {
          id
          title
          slug
        }
      }
      destinations: allWpDestination(
        sort: { order: ASC, fields: title }
        filter: { parentId: { ne: null } }
      ) {
        nodes {
          id
          title
          uri
          excerpt
          wpParent {
            node {
              slug
            }
          }
          slug
          featuredImage {
            node {
              altText
              mediaItemUrl
              gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
            }
          }
        }
      }
    }
  `);

  const getCalculatedDestination = () => {
    return destinations?.filter((destination) => {
      if (tabOption === ALL_DESTINATIONS) {
        if (
          tabs.find((item) => item?.slug === destination?.wpParent?.node?.slug)
        ) {
          return true;
        }
      }
      return destination?.wpParent?.node?.slug === tabOption;
    });
  };

  const destinations = query?.destinations?.nodes || [];
  const tabs = query?.tabs?.nodes || [];

  return (
    <section
      ref={ref}
      className={`tabs-with-grid lg:py-60 py-30${
        module?.extraClass ? " " + module.extraClass : ""
      }`}
    >
      <div className="container-fluid">
        <motion.div
          ref={ref2}
          initial="hidden"
          animate={isInView || hasPlayed ? "show" : "hidden"}
          variants={container}
          className="section-title fade-ani"
        >
          {module?.heading && (
            <motion.div variants={item} className="title-black">
              <h3>{module.heading}</h3>
            </motion.div>
          )}
          {module?.description && (
            <motion.div variants={item} className="content ">
              {parse(module.description)}
            </motion.div>
          )}
        </motion.div>
        <motion.div
          initial="hidden"
          animate={isInView || hasPlayed ? "show" : "hidden"}
          variants={container2}
        >
          <div className="tabs-content tabs-horizontal pt-50 lgscreen:pt-25 fade-ani">
            <ul className="tabs smscreen2:inline-block">
              <li
                onClick={() => {
                  onTabChange(ALL_DESTINATIONS);
                }}
                className={`tab-link cursor-pointer smscreen2:inline-block smscreen2:!mr-30 smscreen2:w-auto  ${
                  tabOption === ALL_DESTINATIONS ? "tab-current" : ""
                }`}
              >
                All Destinations
              </li>
              {tabs.map((destination) => (
                <li
                  key={destination.id}
                  onClick={() => {
                    onTabChange(destination.slug);
                  }}
                  className={`tab-link cursor-pointer smscreen2:inline-block smscreen2:!mr-30 smscreen2:w-auto ${
                    tabOption == destination.slug ? "tab-current" : ""
                  }`}
                >
                  {destination?.title}
                </li>
              ))}
            </ul>
          </div>
        </motion.div>
        <div className="tabs-container mt-50 smscreen2:mt-25">
          <div className="tab-content global-list">
            <motion.div
              ref={ref3}
              initial="hidden"
              animate={isInView2 || hasPlayed2 ? "show" : "hidden"}
              variants={container}
              className="flex flex-wrap lg:mx-minus-15 mx-0 gap-y-10"
            >
              {getCalculatedDestination()
                .slice(0, numOfItemsToDisplay)
                .map((destination) => (
                  <motion.div
                    variants={item}
                    className="lg:w-4/12 w-full lg:px-15 px-0 fade-ani"
                    key={destination.id}
                  >
                    <Link to={destination.uri} className="">
                      <div className="grid-bx fade-ani">
                        <ImageOpt
                          imgData={
                            destination?.featuredImage?.node.mediaItemUrl
                          }
                          imgAlt={destination?.featuredImage?.node?.altText}
                          imgClass="w-full"
                          width={415}
                          height={220}
                        />
                        <div className="grid-bx-content">
                          <div className="title-black ">
                            <h5>{destination?.title}</h5>
                          </div>
                          <div className="title-black mt-10">
                            {parse(destination?.excerpt)}
                          </div>
                          {destinations.filter(
                            (subDestination) =>
                              subDestination?.parentId === destination.id
                          ).length > 0 && (
                            <div className="content ">
                              <ul>
                                {destinations
                                  .filter(
                                    (subDestination) =>
                                      subDestination?.parentId ===
                                      destination.id
                                  )
                                  .map((subDes) => (
                                    <li key={subDes.id}>{subDes.title}</li>
                                  ))}
                              </ul>
                            </div>
                          )}
                          <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20 ">
                            <span className="button btn-red-border">
                              Explore Destination{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </motion.div>
                ))}
            </motion.div>

            {getCalculatedDestination().length > 0 &&
              getCalculatedDestination().length > numOfItemsToDisplay && (
                <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 lg:mt-100 mt-30 fade-ani">
                  <button onClick={onLoadMore} className="button btn-border">
                    <span>Load More Destinations</span>
                    <img
                      src="/images/new-header-btn.png"
                      loading="lazy"
                      alt="button background image"
                    />
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DestinationsListing;
export const DestinationsListingFragment = graphql`
  fragment DestinationsListingFragment on WpPage_Pagecontent_PageContent_DestinationsListing {
    id
    extraClass
    hideSection
    heading
    description
    pageSize
  }
`;
